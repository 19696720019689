import React from "react";
import CertificacionResistencia from "../../components/CertificacionResistencia/CertificacionResistencia";
import Colores from "../../components/Colores/Colores";
import Fenolico from "../../components/Fenolico/Fenolico";
import CarouselSwipe from "../../components/Carousel";
import ImagenProducto from "../../components/ImagenProducto/ImagenProducto";
import ModelosMampara from "../../components/ModelosMampara/ModelosMampara";
import ImagenFinal from "../../assets/Mampara/Foto_mampara_001.jpg";

// CARROUSEL
import imagen1 from "../../assets/Mampara/SLIDE-MAMPARAS-01.jpg";
import imagen2 from "../../assets/Mampara/SLIDE-MAMPARAS-02.png";
import imagen3 from "../../assets/Mampara/SLIDE-MAMPARAS-03.png";
import imagen4 from "../../assets/Mampara/SLIDE-MAMPARAS-04.png";

import "./styles.css";

const Mampara = () => {
  return (
    <div id="maparas" className="marginTop">
      {/* TODO: CARROUSEL Cambiar imágenes */}
      <CarouselSwipe
        className="banner"
        images={[imagen1, imagen2, imagen3, imagen4]}
      >
        <div
          style={{ height: "500px", position: "absolute" }}
          className="carrousel-container w-100 d-flex justify-content-start align-items-center px-3 px-md-5"
        >
          <div className="texto-encima w-100 p-5 d-flex flex-column justify-content-center align-items-center">
            <h1 className="mb-5 text-white">
              <b>Mamparas Sanitarias 3Gar</b>
            </h1>
            <h5 className="text-center">
              Fabricadas con SÓLIDO FENÓLICO de 12.7mm, contamos con más de 300
              colores a elegir, diseño, alta resistencia y durabilidad. ¡Tu
              mejor opción!
            </h5>
          </div>
        </div>
      </CarouselSwipe>
      <div className="backColor">
        <div className="container py-5">
          <ImagenProducto />
        </div>
      </div>
      <div className="container">
        <Fenolico />
      </div>
      <div className="backColor">
        <CertificacionResistencia />
      </div>
      <div className="container my-5">
        <Colores />
      </div>
      <div className="backColor mt-5">
        <div className="container ">
          <h3 className="py-3">
            <b>Modelos de Mampara</b>
          </h3>
          <div className="row">
            <ModelosMampara />
          </div>
        </div>
      </div>
      <div className="container">
        <img src={ImagenFinal} alt="" className="w-100" height="550" />
      </div>
    </div>
  );
};

export default Mampara;
