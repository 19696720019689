import React from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import "./App.css";
import Inicio from "./pages/inicio/index";
import Productos from "./pages/productos/index";
import Footer from "./components/Footer/Footer";
import SuperficieSolida from "./pages/superficieSolida";
import Fachadas from "./pages/fachadas/Index";
import MuroMovil from "./pages/MuroMovil";
import Mampara from "./pages/mampara";
import Puertas from "./pages/puertas";

function App() {
  return (
    <div className="App">
      <Router>
        <div>
          <Navbar />
          <Switch>
            <Route exact path="/">
              <Inicio />
            </Route>
            <Route exact path="/products">
              <Productos />
            </Route>
            <Route exact path="/superficiesolida">
              <SuperficieSolida />
            </Route>
            <Route exact path="/fachadas">
              <Fachadas />
            </Route>
            <Route exact path="/muromovil">
              <MuroMovil />
            </Route>
            <Route exact path="/mampara">
              <Mampara />
            </Route>
            <Route exact path="/puertas">
              <Puertas />
            </Route>
          </Switch>
          <Footer />
        </div>
      </Router>
    </div>
  );
}

export default App;
