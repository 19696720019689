import React from "react";
import iconoMobile from "../../assets/Footer/Whatsapp_Icon.png";
import iconoDesktop from "../../assets/Footer/Whatsapp_Icon_extendido.png";

const Whatsapp = () => {
  return (
    <div>
      <a
        href="https://api.whatsapp.com/send?phone=525579662975"
        target="_blank"
      >
        <img src={iconoMobile} alt="" className=" d-xl-none btn-flotante" />
        <img
          src={iconoDesktop}
          alt=""
          className="d-none d-xl-block btn-flotante"
        />
      </a>
    </div>
  );
};

export default Whatsapp;
