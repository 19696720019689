import React from "react";
import { HashLink } from "react-router-hash-link";
import "./styles.css";

const ProductosTexto = ({ titulo, descripcion, link }) => {
  return (
    <div className="text-center">
      <h1 className="py-3">
        <b>{titulo} </b>
      </h1>
      <p className="py-3">{descripcion} </p>
      <a
        className="btn btn-primary rounded-pill px-5 buttonShadow"
        onClick={() => {
          link();
        }}
      >
        Más información
      </a>
    </div>
  );
};

export default ProductosTexto;
