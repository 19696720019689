import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation } from "swiper/core";

import "./styles.css";

// install Swiper modules
SwiperCore.use([Pagination, Navigation]);

const CarouselSwipe = ({ height, className, images, children }) => {
  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={30}
      loop={true}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      className={`mySwiper ${className}`}
      style={{
        height: `600px`,
      }}
    >
      {images.map((image, index) => {
        return (
          <SwiperSlide key={index.toString()}>
            <img src={image} className="d-block w-100" alt="..." />
            {children}
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default CarouselSwipe;
