import React from "react";
import ColoresItem from "./ColoresItem";
import ImagenColor1 from "../../assets/MuroMovil/01-5.png";
import ImagenColor2 from "../../assets/MuroMovil/02-4.png";
import ImagenColor3 from "../../assets/MuroMovil/03-5.png";
import ImagenColor4 from "../../assets/MuroMovil/04-5.png";
import ImagenColor5 from "../../assets/MuroMovil/05-5.png";
import ImagenColor6 from "../../assets/MuroMovil/06-5.png";

const ColoresMuros = () => {
  return (
    <div className="row">
      <div className=" d-flex flex-column align-items-center">
        <h3>
          <b>Colores de línea</b>
        </h3>
        <small className="mb-5">Selecciona un color</small>
        <div className="row my-4">
          <ColoresItem size="col-4" src={ImagenColor1} />
          <ColoresItem size="col-4" src={ImagenColor2} />
          <ColoresItem size="col-4" src={ImagenColor3} />
        </div>
        <div className="row my-4">
          <ColoresItem size="col-4" src={ImagenColor4} />
          <ColoresItem size="col-4" src={ImagenColor5} />
          <ColoresItem size="col-4" src={ImagenColor6} />
        </div>
        <h4 className="py-5">
          Con más de <b className="text-primary ">350</b> colores a escoger
        </h4>
        {/* TODO: COLORES Abrir archivo */}
        <button
          onClick={null}
          className="btn btn-primary px-4 rounded-pill my-2 buttonShadow"
        >
          Ver más colores
        </button>
      </div>
    </div>
  );
};

export default ColoresMuros;
