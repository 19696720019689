import React from "react";
import { useHistory } from "react-router-dom";

import ProductosTexto from "../../components/Productos/ProductosTexto";
import producto1 from "../../assets/Productos/mamparas.jpg";
import producto2 from "../../assets/Productos/puerta-de-impacto.jpg";
import producto3 from "../../assets/Productos/puerta-metalica.jpg";
import producto4 from "../../assets/Productos/puerta-de-acceso.jpg";
import producto5 from "../../assets/Productos/superficie-solida.jpg";
import producto6 from "../../assets/Productos/muros.jpg";
import producto7 from "../../assets/Productos/fachada.jpg";

import { useElement } from "../../utils/useElement";

import "./styles.css";

const Products = () => {
  const history = useHistory();
  const { scrollToElementId, scrollTop } = useElement();

  return (
    <div className="marginTop">
      <div className="container py-5">
        <div className="row">
          <div className="col-md-6 d-flex align-items-center justify-content-center">
            <ProductosTexto
              titulo="Mamparas sanitarias 3Gar"
              descripcion="Fabricadas con sólido fenólico de 12.7 mm, contamos con más de 300 colores a elegir, diseño, alta resistencia, amplia garantía y durabilidad."
              link={() => {
                scrollTop();
                history.push("mampara");
              }}
            />
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <div className="row">
              <img src={producto1} className="product-card mx-auto" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="backColor">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <div className="row">
                <img src={producto4} className="product-card mx-auto" alt="" />
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-center">
              <ProductosTexto
                titulo="Puertas de acceso 3Gar"
                descripcion="Las puertas 3Gar combinan una amplia
                variedad de diseños con materiales resistentes
                y funcionales, logrando una solución integral
                adaptable a un amplio mercado"
                link={() => {
                  history.push("puertas");
                  scrollToElementId("prime");
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-6 d-flex align-items-center justify-content-center">
            <ProductosTexto
              titulo="Puertas metálica"
              descripcion="Puertas para el ramo hotelero, hospitalario y
              de servicios. Diseñadas para dar resistencia y
              seguridad a los accesos de nuestros clientes.
              Contamos con puertas de multiusos, contra
              fuego, de emergencia y de registros"
              link={() => {
                history.push("puertas");
                scrollToElementId("metalica");
              }}
            />
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <div className="row">
              <img src={producto3} className="product-card mx-auto" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="backColor">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <div className="row">
                <img src={producto2} className="product-card mx-auto" alt="" />
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-center">
              <ProductosTexto
                titulo="Puertas de impacto"
                descripcion="Puertas industrales diseñadas para soportar tráfico pesado. Soportan desde paso peatonal hasta de montagarcas. Fabricadas con materiales de alta resistencia y durabilidad."
                link={() => {
                  history.push("puertas");
                  scrollToElementId("impacto");
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-6 d-flex align-items-center justify-content-center">
            <ProductosTexto
              titulo="Superficie sólida"
              descripcion="Material compuesto por minerales naturales y
              resinas de acrílico de gran resistencia y
              limpieza, sin poro y sin uniones visibles.
              Se utiliza para la fabricación de mobiliario de
              baño, hotelería, restaurantes, hoteles,
              hospitales, en cualquier espacio."
              link={() => {
                scrollTop();
                history.push("superficiesolida");
              }}
            />
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <div className="row">
              <img src={producto5} className="product-card mx-auto" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="backColor">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <div className="row">
                <img src={producto6} className="product-card mx-auto" alt="" />
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-center">
              <ProductosTexto
                titulo="Muros móviles"
                descripcion="Sistema de páneles prefabricados
                independientes entre si, pero al unirse, forman
                un muro divisorio que crea una nueva
                distribución temporal de los espacios con
                capacidades de aislamiento acústico.
                Ideal para centros de conveciones y salas de
                reuniones."
                link={() => {
                  scrollTop();
                  history.push("muromovil");
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-6 d-flex align-items-center justify-content-center">
            <ProductosTexto
              titulo="Recubrimiento de fachada"
              descripcion="Una nueva piel para tus muros.
              Contamos con amplia variedad de diseños
              para exteriores e interiores, desde
              recubrimientos sencillos hasta sistema de
              fachadas ventiladas.
              Acabados en sólido fenólico, superficie sólida
              o laminados plásticos y metálicos."
              link={() => {
                scrollTop();
                history.push("fachadas");
              }}
            />
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <div className="row">
              <img src={producto7} className="product-card mx-auto" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
