import React from "react";
// import ImagenGlobal from "../../components/ImagenGlobal/ImagenGlobal";
import aplicacion1 from "../../assets/SuperficieSolida/Hospitalario.svg";
import aplicacion2 from "../../assets/SuperficieSolida/Industrial.svg";
import aplicacion3 from "../../assets/SuperficieSolida/Comercial.svg";
import aplicacion4 from "../../assets/SuperficieSolida/Hotelero.svg";
import aplicacion5 from "../../assets/SuperficieSolida/Escolar.svg";
import ImagenGlobal2 from "../ImagenGlobal/ImagenGlobal2";

const Aplicaciones = () => {
  return (
    <div className="row py-3 justify-content-center align-items-center">
      <ImagenGlobal2
        textClass="fontbold"
        text="Hospitalario"
        src={aplicacion1}
        size="col-6 col-md-4 col-lg-2"
      />
      <ImagenGlobal2
        textClass="fontbold"
        text="Industrial"
        src={aplicacion2}
        size="col-6 col-md-4 col-lg-2"
      />
      <ImagenGlobal2
        textClass="fontbold"
        text="Comercial / Mixto"
        src={aplicacion3}
        size="col-6 col-md-4 col-lg-2"
      />
      <ImagenGlobal2
        textClass="fontbold"
        text="Hotelero"
        src={aplicacion4}
        size="col-6 col-md-4 col-lg-2"
      />
      <ImagenGlobal2
        textClass="fontbold"
        text="Escolar"
        src={aplicacion5}
        size="col-6 col-md-4 col-lg-2"
      />
    </div>
  );
};

export default Aplicaciones;
