import React from "react";
import ColoresItem from "./ColoresItem";
import cortafuegos1 from "../../assets/Puertas/CORTAFUEGOS/Nogal.png";
import cortafuegos2 from "../../assets/Puertas/CORTAFUEGOS/Roble.png";
import cortafuegos3 from "../../assets/Puertas/CORTAFUEGOS/Wengue.png";

const ColoresCortaFuego = () => {
  return (
    <div className="row">
      <div className=" d-flex flex-column align-items-center">
        <h3>
          <b>Colores</b>
        </h3>
        <div className="row my-1 ">
          <ColoresItem size="col-4" color="Nogal" src={cortafuegos1} />
          <ColoresItem size="col-4" color="Roble" src={cortafuegos2} />
          <ColoresItem size="col-4" color="Wengue" src={cortafuegos3} />
        </div>
      </div>
    </div>
  );
};

export default ColoresCortaFuego;
