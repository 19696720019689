import React from "react";
import rejilla from "../../assets/Puertas/PRIME/rejilla.jpg";
import ventanilla from "../../assets/Puertas/PRIME/ventanilla.jpg";

const AccesoriosPuertas = () => {
  return (
    <div className="row py-5">
      <h3 className="text-center">
        <b>Accesorios</b>
      </h3>
      <div className="w-50 d-flex flex-column justify-content-center alignt-items-center">
        <img src={rejilla} alt="" className="w-100" />
        <b className="text-center">Rejilla</b>
      </div>
      <div className="w-50 d-flex flex-column justify-content-center alignt-items-center">
        <img src={ventanilla} alt="" className="w-100" />
        <b className="text-center">Ventana</b>
      </div>
    </div>
  );
};

export default AccesoriosPuertas;
