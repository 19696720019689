import React, { useState, useEffect } from "react";
import "./styles.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import "./styles.css";
import logo1 from "../../assets/Inicio/logo_hampton_inn-01.svg";
import logo2 from "../../assets/Inicio/logo_iberostar-01.svg";
import logo3 from "../../assets/Inicio/logo_issste-01.svg";
import logo4 from "../../assets/Inicio/logo_mercedes-01.png";
import logo5 from "../../assets/Inicio/logo_ssp-01.png";
import logo6 from "../../assets/Inicio/logo_uvm-01.svg";
// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation } from "swiper/core";

// install Swiper modules
SwiperCore.use([Pagination, Navigation]);

const getScreenSize = () => {
  if (window.innerWidth < 576) {
    return 2;
  } else if (window.innerWidth < 768) {
    return 3;
  } else if (window.innerWidth < 992) {
    return 4;
  } else if (window.innerWidth < 1200) {
    return 5;
  } else {
    return 5;
  }
};

const CarouselLogo = () => {
  const [screenSize, setScreenSize] = useState(2);

  useEffect(() => {
    function doAction() {
      const screenType = getScreenSize();
      setScreenSize(screenType);
    }
    window.addEventListener("resize", doAction);
    doAction();
    return () => window.removeEventListener("resize", doAction);
  }, []);

  return (
    <Swiper
      slidesPerView={screenSize}
      spaceBetween={30}
      slidesPerGroup={3}
      loop={true}
      loopFillGroupWithBlank={true}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      className="mySwiper"
    >
      {/* TODO: CARROUSEL CAMBIAR LOGOS CLIENTES */}
      <SwiperSlide>
        <img src={logo1} className="img-carouselBanner " alt="" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={logo2} className="img-carouselBanner " alt="" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={logo3} className="img-carouselBanner w-50" alt="" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={logo4} className="img-carouselBanner " alt="" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={logo5} className="img-carouselBanner w-50" alt="" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={logo6} className="img-carouselBanner " alt="" />
      </SwiperSlide>
    </Swiper>
  );
};

export default CarouselLogo;
