import React, { useState } from "react";

import americanoSVG from "../../assets/Mampara/Modelo_Americano.svg";
import americanoPNG from "../../assets/Mampara/Mod_Americano.png";
import estandarSVG from "../../assets/Mampara/Modelo_Estandar.svg";
import estandarPNG from "../../assets/Mampara/Mod_Estandar.png";
import europeSVG from "../../assets/Mampara/Modelo_Europeo.svg";
import europePNG from "../../assets/Mampara/Mod_Europeo.png";
import colganteSVG from "../../assets/Mampara/Modelo_Colgante.svg";
import colgantePNG from "../../assets/Mampara/Mod_Colgante.png";
import cabinaEuropeSVG from "../../assets/Mampara/Modelo_Cabina_Europea.svg";
import cabinaEuropePNG from "../../assets/Mampara/Mod_Cab_Europea.png";
import infantilSVG from "../../assets/Mampara/Modelo_Infantil.svg";
import infantilPNG from "../../assets/Mampara/Mod_Imfantil.png";
import modeloEspecial from "../../assets/Mampara/Mod_Especial.png";
import modeloEspecial1 from "../../assets/Mampara/Mod_Especial1.png";
import modeloRegaderaPNG from "../../assets/Mampara/Mod_Regadera.png";
import modeloRegaderaSVG from "../../assets/Mampara/Modelo_Regadera.svg";

import "./styles.css";

const ModelosMampara = () => {
  const [selected, setSelected] = useState(0);

  const listElements = [
    "Estándar básico",
    "Reforzado Americano",
    "Reforzado Europeo",
    "Colgante",
    "Cabina Eureopea",
    "Regaderas",
    "Infantil",
    "Diseño especial",
  ];

  // TODO Mamparas: Agregar nuevas imágenes respecto al elemento seleccionado
  const listImages = [
    {
      img1: estandarSVG,
      img2: estandarPNG,
    },
    {
      img1: americanoSVG,
      img2: americanoPNG,
    },
    {
      img1: europeSVG,
      img2: europePNG,
    },
    {
      img1: colganteSVG,
      img2: colgantePNG,
    },
    {
      img1: cabinaEuropeSVG,
      img2: cabinaEuropePNG,
    },
    {
      img1: modeloRegaderaSVG,
      img2: modeloRegaderaPNG,
    },
    {
      img1: infantilSVG,
      img2: infantilPNG,
    },
    {
      img1: modeloEspecial,
      img2: modeloEspecial1,
    },
  ];

  const handleSelect = (index) => {
    setSelected(index);
  };

  return (
    <>
      <div className="col-md-3 d-flex justify-content-center">
        <ul className="listado text-center bg-primary text-light w-100">
          {listElements.map((element, index) => {
            return (
              <li
                key={index.toString()}
                onClick={() => handleSelect(index)}
                className={index === selected ? "active" : ""}
              >
                {element}
              </li>
            );
          })}
        </ul>
      </div>
      {listImages[selected] && (
        <div className="col-md-9 text-center animate__animated animate__fadeIn">
          {listImages[selected].img1 && (
            <img
              src={listImages[selected].img1}
              alt=""
              className="imagenListado mx-3"
            />
          )}
          {listImages[selected].img2 && (
            <img
              src={listImages[selected].img2}
              alt=""
              className="imagenListado mx-3"
            />
          )}
        </div>
      )}
    </>
  );
};

export default ModelosMampara;
