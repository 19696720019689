import React from "react";
import "./Cards.css";

const Cards = ({ children }) => {
  return (
    <div className="col-md-4 my-1 my-lg-4 py-3 d-flex justify-content-center">
      <div className="cardBorder card_style d-table justify-content-center">
        <div className="my-2">{children}</div>
      </div>
    </div>
  );
};

export default Cards;
