import React from "react";
import "./styles.css";
import CarouselSwipe from "../../components/Carousel";
import BannerInfo from "../../components/BannerInfo/BannerInfo";
import Cards from "../../components/Cards/Cards";
import CarouselLogo from "../../components/CarouselLogo/CarouselLogo";
import { Swiper, SwiperSlide } from "swiper/react";

// CARROUSEL
import slide1 from "../../assets/Inicio/SLIDE/1.jpg";
import slide2 from "../../assets/Inicio/SLIDE/2.jpg";
import slide3 from "../../assets/Inicio/SLIDE/3.jpg";

import Asesoria from "../../assets/Inicio/Asesoría_icon-01.svg";
import Fabrica from "../../assets/Inicio/Fabrica_icon-01.svg";
import Mapa from "../../assets/Inicio/Mapa_icon-01.svg";

// Import Swiper React components
import ARRAYANES01 from "../../assets/Inicio/Clients/ARRAYANES01.png";
import ARRAYANES02 from "../../assets//Inicio/Clients/ARRAYANES02.png";
import HOTEL01 from "../../assets//Inicio/Clients/HOTEL01.png";
import HOTEL02 from "../../assets//Inicio/Clients/HOTEL02.png";
import MARRIOT01 from "../../assets//Inicio/Clients/MARRIOT01.png";
import MARRIOT02 from "../../assets//Inicio/Clients/MARRIOT02.png";
import TOKS01 from "../../assets//Inicio/Clients/TOKS01.png";
import TOKS02 from "../../assets//Inicio/Clients/TOKS02.png";

const Inicio = () => {
  return (
    <div id="home" className="swiper-container-margin">
      {/* TODO: CARROUSEL Cambiar imágenes */}
      <CarouselSwipe className="banner" images={[slide1, slide2, slide3]}>
        <div
          style={{ height: "500px", position: "absolute" }}
          className="carrousel-container w-100 d-flex justify-content-center align-items-center px-3 px-md-5"
        >
          <div className="texto-encima w-100 p-5 d-flex flex-column justify-content-center align-items-center">
            <h1 className="mb-5 text-white">
              <b>Maparas Sanitarias 3Gar</b>
            </h1>
            <h5>Creando nuevas tendencias</h5>
            <h5>De diseño para tu proyecto</h5>
          </div>
        </div>
      </CarouselSwipe>
      <div className="container">
        <BannerInfo />
      </div>
      <div className="backColor">
        <div className="container">
          <div className="row">
            <Cards>
              <div className="text-center px-2">
                <img src={Asesoria} alt="asesoría" className="w-50 my-3" />
                <h4 className="text-primary fw-bold">
                  Asesoría personalizada en todo México
                </h4>
                <p className="d-none d-md-flex">
                  Te ofrecemos asesoría personalizada altamente profesional
                  durante todo tu proyecto, brindamos soluciones a la medida con
                  materiales de alta calidad y durabilidad. Contamos con
                  oficinas de representación en varios estados del país.
                </p>
              </div>
            </Cards>
            <Cards>
              <div className="text-center px-2 t">
                <img src={Fabrica} alt="asesoría" className="w-50 my-3" />
                <h4 className="text-primary fw-bold">Somos fabricantes</h4>
                <p className="d-none d-md-flex">
                  Todos nuestros productos son maquilados por nosotros con los
                  más altos estándares de calidad. Si lo puedes imaginar, lo
                  podemos fabricar
                </p>
              </div>
            </Cards>
            <Cards>
              <div className="text-center px-2 ">
                <img src={Mapa} alt="asesoría" className="w-50 my-3" />
                <h4 className="text-primary fw-bold">
                  Instalamos en todo México
                </h4>
                <p className="d-none d-md-flex">
                  Contamos con capacidad logística para instalar Mamparas
                  Sanitarias, Puertas Prime, Superficie Solida,Puertascontra
                  incendio y Muros móviles en toda la República Mexicana.
                </p>
              </div>
            </Cards>
          </div>
        </div>
      </div>
      <div className="" style={{ position: "relative" }}>
        <div
          className="bg-primary"
          style={{
            width: "100%",
            height: "calc(100% - 20px )",
            top: "10px",
            position: "absolute",
            zIndex: "-2",
          }}
        ></div>
        <div className="companies container my-5 ">
          <CompanieSlide />
        </div>
      </div>
      <div className="clients container my-5 text-center">
        <h3 className="mt-5 mb-0">Algunos de nuestros clientes</h3>
        <CarouselLogo />
      </div>
    </div>
  );
};

export const CompanieSlide = () => {
  const clientData = [
    {
      title: "Puertas de Acceso 3Gar",
      subtitle: (
        <>
          Prime
          <br />
          Variedad de colores
        </>
      ),
      text: (
        <>
          Escuela Arrayanes,
          <br />
          Mérida, Yucatán
        </>
      ),
      img1: ARRAYANES01,
      img2: ARRAYANES02,
    },
    {
      title: "Maparas Sanitarias 3Gar",
      subtitle: (
        <>
          Luxor estándar
          <br />
          Hibiscus cherry 7988
        </>
      ),
      text: (
        <>
          Hotel Crown Paradise,
          <br />
          Riviera Maya, Quintana Roo
        </>
      ),
      img1: HOTEL01,
      img2: HOTEL02,
    },
    {
      title: "Maparas Sanitarias 3Gar",
      subtitle: (
        <>
          Luxor estándar
          <br />
          Black 1595
        </>
      ),
      text: (
        <>
          Hotel Courtyard by Marriott,
          <br />
          Mérida, Yucatán
        </>
      ),
      img1: MARRIOT01,
      img2: MARRIOT02,
    },
    {
      title: "Puertas de Acceso 3Gar",
      subtitle: (
        <>
          Prime - Diseño especial
          <br />
          Landwark Wood 7981
        </>
      ),
      text: (
        <>
          Restaurante Toks,
          <br />
          Ciudad de México
        </>
      ),
      img1: TOKS01,
      img2: TOKS02,
    },
  ];

  return (
    <Swiper
      className="as"
      slidesPerView={1}
      spaceBetween={30}
      loop={true}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      className="mySwiper"
    >
      {clientData.map((data) => {
        return (
          <SwiperSlide>
            <div className="row">
              <div className="d-none d-md-block col-md-4 my-auto text-light text-center py-4">
                <h3 className="mt-2 mb-4 text-white">
                  <b>{data.title}</b>
                </h3>
                <p className="my-4">{data.subtitle}</p>
                <p className="m-0">{data.text}</p>
              </div>
              <div className="col-md-8 d-flex">
                <div className="w-100 me-3">
                  <img src={data.img1} alt="" className="image-1" />
                </div>
                <div className="w-100 d-none d-lg-flex">
                  <img src={data.img2} alt="" className="image-2" />
                </div>
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default Inicio;
