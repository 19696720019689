export const useElement = () => {
  const scrollToElementId = (id, dif = 100) => {
    setTimeout(() => {
      const elem = document.getElementById(id);
      var box = elem.getBoundingClientRect();

      var body = document.body;
      var docEl = document.documentElement;

      var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
      var scrollLeft =
        window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

      var clientTop = docEl.clientTop || body.clientTop || 0;
      var clientLeft = docEl.clientLeft || body.clientLeft || 0;

      var top = box.top + scrollTop - clientTop;
      var left = box.left + scrollLeft - clientLeft;

      window.scrollTo({
        top: top - dif,
        left: 0,
        behavior: "instant",
      });
    }, 20);
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  };

  return { scrollToElementId, scrollTop };
};
