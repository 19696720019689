import React from "react";
import Certificaciones from "../Certificaciones/Certificaciones";
import "./styles.css";
import resistencia1 from "../../assets/Mampara/Resistencias-HPL-01.png";
import resistencia2 from "../../assets/Mampara/Resistencias-HPL-02.png";
import resistencia3 from "../../assets/Mampara/Resistencias-HPL-03.png";
import resistencia4 from "../../assets/Mampara/Resistencias-HPL-04.png";
import resistencia5 from "../../assets/Mampara/Resistencias-HPL-05.png";
import resistencia6 from "../../assets/Mampara/Resistencias-HPL-06.png";

const CertificacionResistencia = () => {
  return (
    <div className="container py-5">
      <div className="py-4">
        <h3>
          <b>Certificaciones de Sólido Fenólico</b>
        </h3>
        <Certificaciones
          FSC={true}
          // GreenGuardGOLD={true}
          GreenGuard={true}
          // IMSS={true}
          // ISO9001={true}
          // ISO90012000={true}
          ISO={true}
          // NFPAMember
          // NFPA={true}
          // NSFInternacional={true}
          PEFC={true}
          SCSGlobal={true}
          // ULGreenguard={true}
          UL={true}
          USGBC={true}
        />
      </div>
      <div className="py-4">
        <h3>
          <b>Resistencias del Sólido Fenólico</b>
        </h3>
        <div className="row py-3 justify-content-center">
          <div className="col-4 col-sm-3 col-lg-2">
            <img className="w-100 px-1" src={resistencia1} alt="" />
          </div>
          <div className="col-4 col-sm-3 col-lg-2">
            <img className="w-100 px-1" src={resistencia2} alt="" />
          </div>
          <div className="col-4 col-sm-3 col-lg-2">
            <img className="w-100 px-1" src={resistencia3} alt="" />
          </div>
          <div className="col-4 col-sm-3 col-lg-2">
            <img className="w-100 px-1" src={resistencia4} alt="" />
          </div>
          <div className="col-4 col-sm-3 col-lg-2">
            <img className="w-100 px-1" src={resistencia5} alt="" />
          </div>
          <div className="col-4 col-sm-3 col-lg-2">
            <img className="w-100 px-1" src={resistencia6} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CertificacionResistencia;
