import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { MenuItems } from "./MenuItems";
import LogoNav from "../../assets/Inicio//MENU TOP/3GAR_white_logo-01.svg";
import "./Navbar.css";

const Navbar = () => {
  const history = useHistory();
  const [state, setState] = useState({ clicked: false });

  const handleToggleMenu = () => {
    setState({ clicked: !state.clicked });
  };

  return (
    <nav className="NavbarItems bg-primary">
      <div className="container-lg d-flex flex-row align-items-center">
        <img src={LogoNav} className="logo" alt="..." />
        <div className="menu-icon" onClick={handleToggleMenu}>
          <i className={state.clicked ? "bi bi-x" : "bi bi-list"}></i>
        </div>
        <ul className={state.clicked ? "nav-menu active" : "nav-menu"}>
          {MenuItems.map((item, index) => {
            return (
              <li key={index.toString()}>
                <a
                  className={item.cName}
                  onClick={() => {
                    handleToggleMenu();
                    history.push(item.url);
                    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
                  }}
                >
                  {item.title}
                </a>
              </li>
            );
          })}
          <ul className="nav-menu-social py-4 py-lg-0 d-flex justify-content-around">
            <li className="nav-menu-social-item">
              <a
                onClick={handleToggleMenu}
                className="bi bi-facebook"
                href="https://www.facebook.com/3GarInnovacion"
                target="_blank"
              ></a>
            </li>
            <li className="nav-menu-social-item">
              <a
                onClick={handleToggleMenu}
                href="https://www.instagram.com/3gar_innovacion"
                className="bi bi-instagram"
                target="_blank"
              ></a>
            </li>
            <li className="nav-menu-social-item">
              <a
                onClick={handleToggleMenu}
                href="https://www.linkedin.com/company/3gar"
                className="bi bi-linkedin"
                target="_blank"
              ></a>
            </li>
          </ul>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
