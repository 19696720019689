import React from "react";

const ImagenGlobal = ({ text, src, size, imgClass, textClass, children }) => {
  return (
    <div className={`col-${size}`}>
      <img src={src} alt="" className={imgClass} />
      <p className={`my-3 ${textClass}`}>{text}</p>
      {children}
    </div>
  );
};

export default ImagenGlobal;
