import React from "react";
import metalica1 from "../../assets/Puertas/METALICAS/Blanco-RAL-9010.png";
import metalica2 from "../../assets/Puertas/METALICAS/Gris-RAL-7001.png";
import metalica3 from "../../assets/Puertas/METALICAS/Imitacion-madera-roble.png";
import metalica4 from "../../assets/Puertas/METALICAS/Imitacion-madera-sapeli.png";
import ColoresItem from "./ColoresItem";

const ColoresMetalica = () => {
  return (
    <div className="row">
      <div className=" d-flex flex-column align-items-center">
        <h3>
          <b>Colores / Pintura</b>
        </h3>
        <div className="row my-1 ">
          <ColoresItem
            size="col-6"
            color="Blanco"
            src={metalica1}
            className="border border-dark"
          />
          <ColoresItem size="col-6" color="Gris" src={metalica2} />
        </div>
        <div className="row my-1">
          <ColoresItem
            size="col-6"
            color="Imitacion madera roble"
            src={metalica3}
          />
          <ColoresItem
            size="col-6"
            color="Imitacion madera sapeli"
            src={metalica4}
          />
        </div>
        <small>*Colores RAL opcionales sobre volumen</small>
      </div>
    </div>
  );
};

export default ColoresMetalica;
