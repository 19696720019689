import React from "react";
import "./BannerInfo.css";
import Logo from "../../assets/Inicio/3GAR_logo-01.svg";

const BannerInfo = () => {
  return (
    <div className="row my-5">
      <div className="col-md-3 d-flex align-items-center">
        <img src={Logo} className="d-block w-100" alt="..." />
      </div>
      <div className="col-md-9 d-flex align-items-center paragraph">
        <p className="text_paragraph text-left my-3">
          Con la experiencia de 4 generaciones de servicio en el ramo de la
          construcción, somos fabricantes de mamparas sanitarias de sólido
          fenólico, puertas para diferentes usos y acabados, muebles y cubiertas
          de superficie sólida, recubrimiento de fachadas y muros móviles.
        </p>
      </div>
    </div>
  );
};

export default BannerInfo;
