import React from "react";
import TituloPagina from "../../components/TituloPagina/TituloPagina";
import ColoresMuros from "../../components/Colores/ColoresMuros";
import CarouselSwipe from "../../components/Carousel";
import corte from "../../assets/MuroMovil/corte-muro.png";
import muestra from "../../assets/MuroMovil/muestra-muro.png";
import deslizador from "../../assets/MuroMovil/deslizador-sencillo.png";
import deslizadordoble from "../../assets/MuroMovil/deslizador-doble.png";
import riel from "../../assets/MuroMovil/riel-de-aluminio.png";
import puertamovil from "../../assets/MuroMovil/Puerta-movil.png";
import muros2 from "../../assets/MuroMovil/muros2.png";

// CARROUSEL
import imagen1 from "../../assets/MuroMovil/SLIDE-MUROS-01.png";

import "./styles.css";

const MuroMovil = () => {
  return (
    <div id="mobile-wall" className="marginTop">
      {/* TODO: CARROUSEL Cambiar imágenes */}
      <CarouselSwipe className="banner" images={[imagen1]}>
        <div
          style={{ height: "500px", position: "absolute" }}
          className="carrousel-container w-100 d-flex justify-content-center align-items-center px-3 px-md-5"
        >
          <div className="texto-encima w-100 p-5 d-flex flex-column justify-content-center align-items-center">
            <h1 className="mb-5 text-white">
              <b>Muros Móviles y Sonoaislantes</b>
            </h1>
            <h5 className="text-center">
              Los Muros Móviles cuentan con un sistema que les permite ser
              independientes entre sí, con la posibilidad de unirse para formar
              un muro divisorio.
            </h5>
            {/* MUCHO TEXTO, se tuve que recortar El sonoaislante es aquel que cumple con las
            funciones anteriores mencionadas pero que también nos brinda la
            oportunidad de aislar el sonido con materiales seguros y durables. */}
          </div>
        </div>
      </CarouselSwipe>
      <TituloPagina Children="Estructura del Muro Móvil" />
      <div className="backColor">
        <div className="container py-3">
          <div className="row">
            <div className="col-md-8 my-auto">
              <img src={corte} alt="" className="w-100" />
            </div>

            <div className="col-md-4">
              <img src={muestra} alt="" className="w-100" />
            </div>
          </div>
        </div>
      </div>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-6">
            <h3>
              <b>¿Por qué son tan útiles?</b>
            </h3>
            <p className=" my-4">
              Son un sistema de páneles prefabricados independientes entre si,
              pero que al unirse forman un muro divisorio que puede estar fijo
              durante el tiempo que lo necesitemos y que cuenta con la facilidad
              de que puede ser quitado cuantas veces se requiera
            </p>
            <h3>
              <b>Elementos</b>
            </h3>
            <div className="row my-4">
              <div className="col-md-6 d-flex justify-content-center align-items-center">
                <span>
                  <b>Deslizador sencillo</b>
                </span>
              </div>
              <div className="col-md-6">
                <img
                  src={deslizador}
                  alt=""
                  className="w-50 muroImagenCentrada"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 d-flex justify-content-center align-items-center">
                <span>
                  <b>Deslizador doble</b>
                </span>
              </div>
              <div className="col-md-6">
                <img
                  src={deslizadordoble}
                  alt=""
                  className="w-50 muroImagenCentrada"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 d-flex justify-content-center align-items-center">
                <span>
                  <b>Riel de aluminio anodizado</b>
                </span>
              </div>
              <div className="col-md-6">
                <img src={riel} alt="" className="w-50 muroImagenCentrada" />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <ColoresMuros />
          </div>
        </div>
      </div>
      <div className="backColor">
        <div className="container pb-5">
          <div className="row">
            <div className="col-md-8 d-flex align-items-center">
              <h3>
                <b>Puertas de Comunicación</b>
                <p className="textoMuroMovil py-3">
                  Dentro de los muros móviles es posible colocar puertas que nos
                  permitan un fácil acceso a nuestras áreas divididas, con la
                  oportunidad de mantener el mismo acabado, ayudandonos a
                  presentar una buena estética y dar un toque de elegancia a
                  nuestros proyectos.
                </p>
              </h3>
            </div>
            <div className="col-md-4">
              <img src={puertamovil} alt="" className="w-75" />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <img src={muros2} alt="" height="450" className="w-100 imagenFinal" />
      </div>
    </div>
  );
};

export default MuroMovil;
