import React from "react";

const ImagenGlobal2 = ({ text, src, size, imgClass, textClass, children }) => {
  return (
    <div
      className={`d-flex flex-column justify-content-center align-items-center ${size}`}
    >
      <img
        src={src}
        alt=""
        style={{ maxWidth: "120px" }}
        className={imgClass}
      />
      <p className={`my-3 ${textClass}`}>{text}</p>
      {children}
    </div>
  );
};

export default ImagenGlobal2;
