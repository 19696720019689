import React from "react";
import ImagenMuestra from "../../assets/Mampara/Mod_Europeo.png";
import ColoresItem from "./ColoresItem";
import ImagenColor1 from "../../assets/Mampara/colores/1595-black.jpg";
import ImagenColor2 from "../../assets/Mampara/colores/4633-natural-nebula.jpg";
import ImagenColor3 from "../../assets/Mampara/colores/4830-satin-stainless.jpg";

const Colores = () => {
  return (
    <div className="row">
      <div className="col-md-7 d-flex flex-column align-items-center">
        <h3>
          <b>Colores de línea</b>
        </h3>
        <small>Selecciona un color</small>
        <div className="row my-4">
          <ColoresItem
            size="col-4"
            color="Black"
            numero="1595"
            src={ImagenColor1}
          />
          <ColoresItem
            size="col-4"
            color="Natural Nebula"
            numero="4633"
            src={ImagenColor2}
          />
          <ColoresItem
            size="col-4"
            color="Satin Stainless"
            numero="4830"
            src={ImagenColor3}
          />
        </div>
        <h4>
          Con más de <b className="text-primary">350</b> colores a escoger
        </h4>
        {/* TODO: COLORES Abrir archivo */}
        <button
          onClick={null}
          className="btn btn-primary px-4 rounded-pill my-4 buttonShadow"
        >
          Ver más colores
        </button>
      </div>
      <div className="col-md-5 d-flex justify-content-center align-items-center">
        <img src={ImagenMuestra} alt="..." className="w-75 " />
      </div>
    </div>
  );
};

export default Colores;
