import React from "react";
import PrimeColor1 from "../../assets/Puertas/PRIME/1595-black.jpg";
import PrimeColor2 from "../../assets/Puertas/PRIME/4633-natural-nebula.jpg";
import PrimeColor3 from "../../assets/Puertas/PRIME/4830-satin-stainless.jpg";
import PrimeColor4 from "../../assets/Puertas/PRIME/7949k-asian-night-2.jpg";
import MarcoPrime1 from "../../assets/Puertas/PRIME/chambrana.jpg";
import MarcoPrime2 from "../../assets/Puertas/PRIME/marco-europeo.jpg";
import MarcoPrime3 from "../../assets/Puertas/PRIME/marco-recto.jpg";
import ColoresItem from "./ColoresItem";

const ColoresPrime = () => {
  return (
    <div className="row">
      <div className=" d-flex flex-column align-items-center">
        <h3>
          <b>Colores de línea</b>
        </h3>
        <small className="mb-3">Selecciona un color</small>
        <div className="row my-1">
          <ColoresItem size="col-6" color="Natural Nébula" src={PrimeColor1} />
          <ColoresItem size="col-6" color="Satin Stainless" src={PrimeColor2} />
        </div>
        <div className="row my-1">
          <ColoresItem size="col-6" color="Asiaun Night" src={PrimeColor3} />
          <ColoresItem size="col-6" color="Black" src={PrimeColor4} />
        </div>
        <h4 className="py-2">
          Con más de <b className="text-primary ">350</b> colores a escoger
        </h4>
        {/* TODO: COLORES Abrir archivo */}
        <button
          onClick={null}
          className="btn btn-primary px-4 rounded-pill my-2"
        >
          Ver más colores
        </button>
      </div>
      <h3 className="text-center">
        <b>Marcos</b>
      </h3>
      <div className="row my-2 d-flex justify-content-between">
        <img src={MarcoPrime1} alt="" className="w-25" />
        <img src={MarcoPrime2} alt="" className="w-25" />
        <img src={MarcoPrime3} alt="" className="w-25" />
      </div>
    </div>
  );
};

export default ColoresPrime;
