import React from "react";
import { useHistory } from "react-router-dom";
import { useElement } from "../../utils/useElement";

import Copyright from "./Copyright";
import Whatsapp from "./Whatsapp";
import LogoFooter from "../../assets/Inicio/3GAR_logo-01.svg";

import "./styles.css";

const Footer = () => {
  const history = useHistory();
  const { scrollToElementId, scrollTop } = useElement();

  return (
    <div className="backcolorFooter">
      <div className="container ">
        <div className="row">
          <div className="col-md-4  my-4 py-3">
            <div>
              <img src={LogoFooter} className="logoFooter my-3" alt="" />

              <p>
                Con 4 generaciones de experiencia en el ramo de la construcción,
                somos fabricantes de mamparas sanitarias de sólido fenólico,
                puertas para diferentes usos y acabados, muebles y cubiertas de
                superficies sólida, recubrimiento de fachadas y muros móviles.
              </p>
            </div>
          </div>
          <div className="col-md-4  my-4 py-3">
            <h3 className="my-3">Contáctanos</h3>
            <div>
              <p>
                <i className="bi bi-geo-alt-fill"></i>
                <a
                  className="linksFooter"
                  href="https://g.page/3garDisenoSinlimite?share"
                >
                  Calle 97 No. 358-G x 44 y 44-A
                </a>
                <br />
                <a
                  className="linksFooter"
                  href="https://g.page/3garDisenoSinlimite?share"
                >
                  Col. Santa Rosa, Mérida, Yucatán, C.P. 97279
                </a>
              </p>
              <p>
                <i className="bi bi-envelope"></i>{" "}
                <a className="linksFooter" href="mailto:contacto@3gar.mx">
                  contacto@3gar.mx
                </a>
              </p>
              <p>
                <i
                  className="bi bi-telephone-fill"
                  href="tel:+528002013427"
                ></i>{" "}
                <a className="linksFooter" href="tel:+528002013427">
                  800-201-3427
                </a>
              </p>
              <p>
                <i className="bi bi-whatsapp"></i>{" "}
                <a
                  className="linksFooter"
                  href="wa.link/ha2ek2"
                  target="_blank"
                >
                  +52 (55) 7966 2975
                </a>
              </p>
            </div>
          </div>
          <div className="col-md-4 my-4 py-3">
            <h3>Nuestros productos</h3>
            <ul className="px-3 vinietas">
              <li className="footer_link">
                <a
                  onClick={() => {
                    scrollTop();
                    history.push("mampara");
                  }}
                >
                  Mamparas sanitarias 3Gar
                </a>
              </li>
              <li className="footer_link">
                <a
                  onClick={() => {
                    history.push("puertas");
                    scrollToElementId("prime");
                  }}
                >
                  Puertas de acceso 3Gar
                </a>
              </li>
              <li className="footer_link">
                <a
                  onClick={() => {
                    history.push("puertas");
                    scrollToElementId("metalica");
                  }}
                >
                  Puertas contra fuego
                </a>
              </li>
              <li className="footer_link">
                <a
                  onClick={() => {
                    history.push("puertas");
                    scrollToElementId("impacto");
                  }}
                >
                  Puertas de impacto
                </a>
              </li>
              <li className="footer_link">
                <a
                  onClick={() => {
                    scrollTop();
                    history.push("superficiesolida");
                  }}
                >
                  Superficie sólida
                </a>
              </li>
              <li className="footer_link">
                <a
                  onClick={() => {
                    scrollTop();
                    history.push("muromovil");
                  }}
                >
                  Muros móviles
                </a>
              </li>
              <li className="footer_link">
                <a
                  onClick={() => {
                    scrollTop();
                    history.push("fachadas");
                  }}
                >
                  Recubrimiento de fachadas
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Copyright />
      <Whatsapp />
    </div>
  );
};

export default Footer;
