import React from "react";

const ColoresItem = ({ color, numero, src, size }) => {
  return (
    <>
      <div className={`${size} d-grid text-center`}>
        <img
          src={src}
          alt=""
          className="w-100 d-block m-auto border border-dark"
        />
        <small>{color} </small>
        <small>{numero} </small>
      </div>
    </>
  );
};

export default ColoresItem;
