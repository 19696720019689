import React from "react";

const Copyright = () => {
  return (
    <div className="bg-primary text-light text-center py-3">
      <span>
        <b>Todos los derechos reservados 3Gar 2021</b>
      </span>
    </div>
  );
};

export default Copyright;
