import React from "react";
import ImagenGlobal from "../../components/ImagenGlobal/ImagenGlobal";

import FSC from "./assets/FSC.svg";
import GreenGuardGOLD from "./assets/GreenGuardGOLD.svg";
import GreenGuard from "./assets/GreenGuard.svg";
import IMSS from "./assets/IMSS.svg";
import ISO9001 from "./assets/ISO9001.svg";
import ISO90012000 from "./assets/ISO90012000.svg";
import ISO from "./assets/ISO.svg";
import NFPAMember from "./assets/NFPAMember.svg";
import NFPA from "./assets/NFPA.svg";
import NSFInternacional from "./assets/NSFInternacional.svg";
import PEFC from "./assets/PEFC.svg";
import SCSGlobal from "./assets/SCSGlobal.svg";
import ULGreenguard from "./assets/ULGreenguard.svg";
import UL from "./assets/UL.svg";
import USGBC from "./assets/USGBC.svg";

const Certificaciones = (
  props = {
    FSC: false,
    GreenGuardGOLD: false,
    GreenGuard: false,
    IMSS: false,
    ISO9001: false,
    ISO90012000: false,
    ISO: false,
    NFPAMember: false,
    NFPA: false,
    NSFInternacional: false,
    PEFC: false,
    SCSGlobal: false,
    ULGreenguard: false,
    UL: false,
    USGBC: false,
  }
) => {
  if (!props) return <div></div>;

  return (
    <div className="row seven-cols py-3 text-center justify-content-center">
      {props.FSC && <ImagenGlobal src={FSC} size="1 col-sm-1 col-lg-1" />}
      {props.GreenGuardGOLD && (
        <ImagenGlobal src={GreenGuardGOLD} size="1 col-sm-1 col-lg-1" />
      )}
      {props.GreenGuard && (
        <ImagenGlobal src={GreenGuard} size="1 col-sm-1 col-lg-1" />
      )}
      {props.IMSS && <ImagenGlobal src={IMSS} size="1 col-sm-1 col-lg-1" />}
      {props.ISO9001 && (
        <ImagenGlobal src={ISO9001} size="1 col-sm-1 col-lg-1" />
      )}
      {props.ISO90012000 && (
        <ImagenGlobal src={ISO90012000} size="1 col-sm-1 col-lg-1" />
      )}
      {props.ISO && <ImagenGlobal src={ISO} size="1 col-sm-1 col-lg-1" />}
      {props.NFPAMember && (
        <ImagenGlobal src={NFPAMember} size="1 col-sm-1 col-lg-1" />
      )}
      {props.NFPA && <ImagenGlobal src={NFPA} size="1 col-sm-1 col-lg-1" />}
      {props.NSFInternacional && (
        <ImagenGlobal src={NSFInternacional} size="1 col-sm-1 col-lg-1" />
      )}
      {props.PEFC && <ImagenGlobal src={PEFC} size="1 col-sm-1 col-lg-1" />}
      {props.SCSGlobal && (
        <ImagenGlobal src={SCSGlobal} size="1 col-sm-1 col-lg-1" />
      )}
      {props.ULGreenguard && (
        <ImagenGlobal src={ULGreenguard} size="1 col-sm-1 col-lg-1" />
      )}
      {props.UL && <ImagenGlobal src={UL} size="1 col-sm-1 col-lg-1" />}
      {props.USGBC && <ImagenGlobal src={USGBC} size="1 col-sm-1 col-lg-1" />}
    </div>
  );
};

export default Certificaciones;
