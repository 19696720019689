import React from "react";
import ColoresItem from "./ColoresItem";
import ImagenColor1 from "../../assets/Mampara/colores/1595-black.jpg";
import ImagenColor2 from "../../assets/Mampara/colores/4633-natural-nebula.jpg";
import ImagenColor3 from "../../assets/Mampara/colores/4830-satin-stainless.jpg";
import ImagenColor4 from "../../assets/Fachada/7949k-asian-night-2.jpg";
import ImagenColor5 from "../../assets/Fachada/10745-fonthill-pear-2.jpg";

const ColoresFachada = () => {
  return (
    <div className="row">
      <div className=" d-flex flex-column align-items-center">
        <h3>Colores de línea</h3>
        <small>Selecciona un color</small>
        <div className="row my-4">
          <ColoresItem
            size="col-4"
            color="Black"
            numero="1595"
            src={ImagenColor1}
          />
          <ColoresItem
            size="col-4"
            color="Natural Nebula"
            numero="4633"
            src={ImagenColor2}
          />
          <ColoresItem
            size="col-4"
            color="Satin Stainless"
            numero="4830"
            src={ImagenColor3}
          />
        </div>
        <div className="row">
          <div className="col-2 col-sm-2 col-lg-2"></div>
          <ColoresItem
            size="col-4"
            color="Asian Night"
            numero="7949"
            src={ImagenColor4}
          />
          <ColoresItem
            size="col-4"
            color="Fonthill Pear"
            numero="10745"
            src={ImagenColor5}
          />
          <div className="col-2 col-sm-2 col-lg-2"></div>
        </div>
        <h4 className="py-3">
          Con más de <b className="text-primary ">350</b> colores a escoger
        </h4>
        {/* TODO: COLORES Abrir archivo */}
        <button
          onClick={null}
          className="btn btn-primary px-4 rounded-pill my-4 buttonShadow"
        >
          Ver más colores
        </button>
      </div>
    </div>
  );
};

export default ColoresFachada;
