import React from "react";

const TituloPagina = ({ Children, id }) => {
  return (
    <div className="text-center py-4 bg-primary" id={id}>
      <h1 className="text-light">
        <b> {Children} </b>
      </h1>
    </div>
  );
};

export default TituloPagina;
