import React from "react";
import muestra from "../../assets/Mampara/MUESTRA_HPL.jpg";

const Fenolico = () => {
  return (
    <div>
      <div className="row my-5">
        <div className="col-md-6 d-flex align-items-center">
          <img src={muestra} className="d-block w-100" alt="..." />
        </div>
        <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
          <h3 className="text-center">
            <b>Sólido Fenólico</b>
          </h3>
          <p className=" text-center my-3">
            Las mamparas línea Luxor de 3GAR son fabricadas con Sólido Fenólico
            (laminado compacto) de 12.7mm de espesor. Este es un material ideal
            por su alta resistencia al grafiti, vapor, humedad, abrasión y los
            golpes. Ya que no contiene bastidor metálico, elimina los problemas
            de oxidación y es muy fácil de limpiar. Se considera un producto de
            "mantenimiento cero", cuenta con certificaciones internacionales y
            una amplia gama de colores
          </p>
        </div>
      </div>
    </div>
  );
};

export default Fenolico;
