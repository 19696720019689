export const MenuItems = [
  {
    title: "Inicio",
    url: "/",
    cName: "nav-links",
  },
  {
    title: "Productos",
    url: "/products",
    cName: "nav-links",
  },
];
