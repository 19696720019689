import React from "react";
import "./styles.css";
import producto1 from "../../assets/Mampara/Bloqueo-delta.jpg";
import producto2 from "../../assets/Mampara/Bisagra-automatico.jpg";
import producto3 from "../../assets/Mampara/Nueva-Cerradura.jpg";
import producto4 from "../../assets/Mampara/Garantia.svg";

const ImagenProducto = () => {
  return (
    <div className="row">
      <div className="col-6 col-md-3 text-center">
        <img src={producto1} alt="..." className="imgTamanio" />
        <p className="m-3 text-center">
          Traslape con <br /> diseño Delta
        </p>
      </div>
      <div className="col-6 col-md-3 text-center">
        <img src={producto2} alt="..." className="imgTamanio" />
        <p className="m-3 text-center">
          Bisagra con <br /> cierre automático
        </p>
      </div>
      <div className="col-6 col-md-3 text-center">
        <img src={producto3} alt="..." className="imgTamanio" />
        <p className="m-3 text-center">
          Nueva chapa de <br /> sólido fenólico
        </p>
      </div>
      <div className="col-6 col-md-3 text-center">
        <img src={producto4} alt="..." className="imgTamanio" />
        <p className="m-3 text-center">
          Garantía de 10 años <br />
          en el producto
        </p>
      </div>
    </div>
  );
};

export default ImagenProducto;
