import React from "react";
import "./styles.css";
import Aplicaciones from "../../components/Aplicaciones/Aplicaciones";
import CarouselSwipe from "../../components/Carousel";
import TituloPagina from "../../components/TituloPagina/TituloPagina";
import prime1 from "../../assets/Puertas/PRIME/Corte-puerta-prime.jpg";
import prime2 from "../../assets/Puertas/PRIME/modelo-prime.jpg";
import elegance1 from "../../assets/Puertas/ELEGANCE/Corte-Elegance.jpeg";
import elegance2 from "../../assets/Puertas/ELEGANCE/Modelo-elegance.jpg";
import metalica1 from "../../assets/Puertas/METALICAS/puerta-metal-1.jpg";
import metalica2 from "../../assets/Puertas/METALICAS/puerta-metal-2.jpg";
import cortafuegos1 from "../../assets/Puertas/CORTAFUEGOS/puerta-cortafuegos-1.jpg";
import cortafuegos2 from "../../assets/Puertas/CORTAFUEGOS/puerta-cortafuegos-2.jpg";
import cortafuegos3 from "../../assets/Puertas/CORTAFUEGOS/puerta-cortafuegos-3.jpg";
import impacto1 from "../../assets/Puertas/IMPACTO/Croquis-puerta-impacto.jpg";
import impacto2 from "../../assets/Puertas/IMPACTO/puertas-de-impacto-1.jpg";
import AccesoriosPuertas from "../../components/AccesoriosPuertas/AccesoriosPuertas";
import ColoresPrime from "../../components/Colores/ColoresPrime";
import ColoresMetalica from "../../components/Colores/ColoresMetalica";
import ColoresCortaFuego from "../../components/Colores/ColoresCortaFuego";

// CARROUSEL
import imagen1 from "../../assets/Puertas/SLIDE-PUERTAS-01.jpg";
import imagen2 from "../../assets/Puertas/SLIDE-PUERTAS-02.jpg";

const Puertas = () => {
  return (
    <div className="marginTop">
      {/* TODO: CARROUSEL Cambiar imágenes */}
      <CarouselSwipe className="banner" images={[imagen1, imagen2]}>
        <div
          style={{ height: "500px", position: "absolute" }}
          className="carrousel-container w-100 d-flex justify-content-start align-items-center px-3 px-md-5"
        >
          <div className="texto-encima w-100 p-5 d-flex flex-column justify-content-center align-items-center">
            <h1 className="mb-5 text-white">
              <b>Puertas 3Gar</b>
            </h1>
            <h5 className="text-center">
              Nuestras puertas combinan con un diseño especial con acabados
              resistentes y durables, logrando una solución integral,
              adaptándose a un amplio mercado
            </h5>
          </div>
        </div>
      </CarouselSwipe>
      <div className="container my-5">
        <h3 className="text-center">
          <b>Aplicaciones</b>
        </h3>
        <Aplicaciones />
      </div>
      <TituloPagina Children="Puertas línea Prime" id="prime" />
      <div className="backColor">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <img src={prime1} alt="" className="w-75" />
            </div>
            <div className="col-md-4 d-flex flex-column justify-content-center">
              <ol className="">
                <li>Marco de Alumino Perfil europeo 3GAR</li>
                <li>Felpa</li>
                <li>Sólido fenólico de 3 mm</li>
                <li>Perfil de aluminio 3GAR</li>
                <li>Madera de pino estufada de primera</li>
                <li>Honey Comb 1 1/4</li>
                <li>Sólido fenólico de 1 mm</li>
              </ol>
            </div>
            <div className="col-md-4">
              <img src={prime2} alt="" className="w-75 my-5" />
            </div>
          </div>
        </div>
      </div>
      <div className="container py-3">
        <div className="row">
          <div className="col-md-6">
            <p>
              Las <b>Puertas Prime</b> están diseñadas para brindar estética,
              funcionalidad y dulabilidad. <br /> Ideal para zonas de uso
              continuo como escuelas o áreas públicas
            </p>
            <h4 className="mt-5">
              <b>Características</b>
            </h4>
            <ul>
              <li>
                Alta resistencia al grafiti, vapor, humedad, abrasión y los
                golpes
              </li>
              <li>Cantos protegidos con perfil de aluminio de diseño</li>
              <li>
                Acabado en sólido fenólico de 3mm, un material de "mantenimiento
                cero" y con certificaciones ambientales internacionales
              </li>
              <li>
                Opcional en acabado acero inoxidable o lámina procenalizada
              </li>
              <li>Desague integrado en perfiles</li>
              <li>Marco de aluminio en variedad de colores</li>
              <li>Amplia gana de colores</li>
              <li>3 años de garantía</li>
            </ul>
            <AccesoriosPuertas />
          </div>
          <div className="col-md-6">
            <ColoresPrime />
          </div>
        </div>
      </div>
      <TituloPagina Children="Puertas línea Elegance" />
      <div className="backColor">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <img src={elegance1} alt="" className="w-100 mt-5" />
            </div>

            <div className="col-md-5">
              <img src={elegance2} alt="" className="w-75 my-5" />
            </div>
          </div>
        </div>
      </div>
      <div className="container py-3">
        <div className="row">
          <div className="col-md-6">
            <p>
              Las <b>Puertas Elegance</b> son puertas fenólicas pero sin cantos
              de aluminio, dando un diseño más limpio y más residencial, sin
              perder sus características, estética, funcionalidad y durabilidad
            </p>
            <h4 className="mt-5">
              <b>Características</b>
            </h4>
            <ul>
              <li>
                Alta resistencia al grafiti, vapor, humedad, abrasión y los
                golpes
              </li>
              <li>Cantos de sólido fenólico 9mm color negro</li>
              <li>
                Acabado en sólido fenólico de 3mm, un material de "mantenimiento
                cero" y con certificaciones ambientales internacionales
              </li>
              <li>Marco de aluminio en variedad de colores</li>
              <li>Amplia gana de colores</li>
              <li>3 años de garantía</li>
            </ul>
            <AccesoriosPuertas />
          </div>
          <div className="col-md-6">
            <ColoresPrime />
          </div>
        </div>
      </div>
      <TituloPagina Children="Puertas Metálicas" id="metalica" />
      <div className="backColor">
        <div className="container">
          <div className="row">
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <img src={metalica1} alt="" className="w-75 my-5" />
            </div>

            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <img src={metalica2} alt="" className="w-75 my-5" />
            </div>
          </div>
        </div>
      </div>
      <div className="container py-3">
        <div className="row">
          <div className="col-md-6">
            <p>
              La solución para espacios interiores o exteriores que requieren
              seguridad y alto tráfico.
            </p>
            <h4 className="mt-5">
              <b>Características</b>
            </h4>
            <ul className="marginListaPuertas">
              <li>
                <b>Usos múltiples</b> - Robustez y resistencia similar a una
                puerta contrafuegos ideal para todo tipo de espacios donde se
                requiera un "plus" en calidad de materiales y acabados
              </li>
              <li>
                <b>Corta fuegos</b> - Puertas diseñadas para una gran variedad
                de edificios garantizando la seguridad de dichos espacios,
                cuenta con una resistencia al fuego de 90 y 180 minutos; y un
                sello perimetal que se expande para evitar la propagación del
                humo en el lugar donde se requiere protección
              </li>
            </ul>
            <AccesoriosPuertas />
          </div>
          <div className="col-md-6">
            <ul className="marginListaPuertas">
              <li>
                <b>Emergencia</b> - Puertas ubicadas en puntos estretégicos y/o
                críticos en caso de algún sinistro y que permiten guardar la
                seguridad del edificio y sus ocupantes mientras son evacuados
                cumpliendo con todas las normas o reglamentos de protección
                civil
              </li>
              <li>
                <b>Registros</b> - Diseñadas especialmente para compartimentos
                de agua, luz, gas, telecomunicaciones, cuadros eléctricos, etc.
                garantizando la seguridad de dichos espacios, la puerta para
                registros cuenta con una resistencia al fuego de 60 minutos
              </li>
              <li>
                <b>Acústicas</b> - Ofrecen una solución ideal para edificios que
                necesiten disminución de ruido de un sector a otro.
                Estructuradas en lámina de calibre 16, con sellos perimetales e
                inferiores contra ruido y un marco doble batiente calibre 16 que
                hacen más eficiente su desempeño
              </li>
            </ul>
            <ColoresMetalica />
          </div>
        </div>
      </div>

      <TituloPagina Children="Puertas de Madera Corta Fuegos" />
      <div className="backColor">
        <div className="container">
          <div className="row">
            <div className="col-md-4 d-flex justify-content-center align-items-center">
              <img src={cortafuegos1} alt="" className="w-75 my-5" />
            </div>
            <div className="col-md-4 d-flex justify-content-center align-items-center">
              <img src={cortafuegos2} alt="" className="w-75 my-5" />
            </div>
            <div className="col-md-4 d-flex justify-content-center align-items-center">
              <img src={cortafuegos3} alt="" className="w-75 my-5" />
            </div>
          </div>
        </div>
      </div>
      <div className="container py-3">
        <div className="row">
          <div className="col-md-6">
            <h4>
              <b>Características</b>
            </h4>
            <ul className="marginListaPuertas">
              <li>
                Fabricada con Core Tubular, haciéndola un 60% más ligera que una
                puerta de tablero de partículas sólida.
              </li>
              <li>No aumenta su espesor evitando defectos futuros</li>
              <li>
                Acabados en caras con MDF enchapadas o laminados según diseño
              </li>
              <li>Retardate al fuego con core tubular de 38mm de 20 min.</li>
              <li>No se pandea ni deforma</li>
              <li>Aislamiento acústico en puertas de 45mm de 29dB </li>
              <li>
                Amigable con el medio ambiente ya que solo requiere la mitad de
                recursos forestales vs puerta sólido
              </li>
              <li>Maroco metálico color sólido, tono similar a la puerta</li>
            </ul>
          </div>
          <div className="col-md-6">
            <ColoresCortaFuego />
            <AccesoriosPuertas />
          </div>
        </div>
      </div>

      <TituloPagina Children="Puertas de Impacto" id="impacto" />
      <div className="backColor">
        <div className="container">
          <div className="row">
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <img src={impacto1} alt="" className="w-75 my-5" />
            </div>
            {/* <div className="col-md-4 "></div> */}
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <img src={impacto2} alt="" className="w-100 my-5" />
            </div>
          </div>
        </div>
      </div>
      <div className="container py-3">
        <div className="row">
          <div className="col-md-6">
            <p>
              Puertas diseñadas y construidas para soportar alto tráfico
              continuo
            </p>
            <p>
              La solución para el movimiento rápido y eficiento en ambos
              sentidos
            </p>
            <h4>
              <b>Características</b>
            </h4>
            <ul className="marginListaPuertas mx-0">
              <li>Fabricadas a la medida</li>
              <li>Espesor de 1 1/4 con aislamiento de poliuretano</li>
              <li>
                Bisagra tipo leva "V" fabricada en aluminio (abre al impacto y
                cierra automáticamente)
              </li>
              <li>Chapas exteriores de plástico ABS de 1/4</li>
              <li>Ventana con 2 caras de policarbonato</li>
              <li>Placa de pateo o bumper parachoques</li>
              <li>
                Modelos Uniswing (Para áreas de tráfico peatonal) y Swing (Para
                áreas de trabajo con uso de montacargas)
              </li>
              <li>
                Marco metálico PTR pintado y marco de Acero Inoxidable que se
                monta en el muro existente{" "}
              </li>
            </ul>
            <div className="row ">
              <div className="col-md-6 d-grid justify-content-center">
                <h3>
                  <b>Colores</b>
                </h3>
                <p>- Blanco</p>
                <p>- Gris</p>
              </div>
              <div className="col-md-6 d-grid justify-content-center">
                <h3>
                  <b>Accesorios</b>
                </h3>
                <p>- Bisagra</p>
                <p>- Chapa</p>
                <p>- Placa/bumper</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <h4>
              <b>Características</b>
            </h4>
            <p>- Puerta sencilla o doble de 1 1/4 de espesor, abatible 180°</p>
            <p>
              - Bisagra tipo leva v-cam en aluminio alevado, para elevación de 1
              1/2 y cierre asistido por resorte
            </p>
            <p>- Acabado chapas exteriores de plástico ABS de 1/8</p>
            <p>
              - Núcleo: espuma rígida de poliuretano inyectada en molde
              "feam-in-place"
            </p>
            <p>
              - Ensamble: a base de tubo de acero, armado con perfiles rígidos
              de ABS
            </p>
            <p>- Empaques vs polvo: vinil fleible en verticales</p>
            <p>- Extrusiones de plástico flexible en verticales</p>
            <p>- Cubre leva: lámina de acerto galv</p>
            <p>- Prepintado + vinil flexible reforzado</p>
            <p>
              - Ventana rectangular: 10 1/2 x 23 (sandwich sellado con dos caras
              de acrílico, marco de plástico inyectado)
            </p>
            <p>
              - Colores disponibles en caras de puerta y placa de protección de
              plástico ABS. Estándar: Blanco y negro (con textura) Especial:
              rojo, gris, beige (con textura) Blanco kp (liso)
            </p>
            <p>
              - Opciones de acero inoxidable: Bisagra Easy Hinge (sin resorte)
              Tubo soporte, Guarda metálica con buje, Tornillería expuesta
            </p>
            <p>
              - Tipos de montaje: sobre marco de acero, ancho 4", 5" o 6" Sobre
              mampostería, sobre madera
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Puertas;
