import React from "react";
import TituloPagina from "../../components/TituloPagina/TituloPagina";
import ColoresFachada from "../../components/Colores/ColoresFachada";
import Certificaciones from "../../components/Certificaciones/Certificaciones";
import CarouselSwipe from "../../components/Carousel";

import CorteFachada from "../../assets/Fachada/Corte-fachada-ventilada.png";
import croquis from "../../assets/Fachada/croquis_composicion_solido_fenolico.jpg";
import resistencia from "../../assets/Fachada/Resistencias-HPL.png";
import FachadaVentilada from "../../assets/Fachada/fachadas_ventiladas_recubrimiento.jpg";

import "./styles.css";

// CARROUSEL
import imagen1 from "../../assets/Fachada/SLIDE-FACHADA-01.png";

const Fachadas = () => {
  return (
    <div className="marginTop">
      {/* TODO: CARROUSEL Cambiar imágenes */}
      <CarouselSwipe className="banner" images={[imagen1]}>
        <div
          style={{ height: "500px", position: "absolute" }}
          className="carrousel-container w-100 d-flex justify-content-center align-items-center px-3 px-md-5"
        >
          <div className="texto-encima w-100 p-5 d-flex flex-column justify-content-center align-items-center">
            <h1 className="mb-5 text-white">
              <b>Fachada ventilada</b>
            </h1>
            <h5>La fachada ventilada, es una nueva piel para su</h5>
            <h5>edificio, que brinda protección térmica-acústica y</h5>
            <h5>resistencia a la intemperie.</h5>
          </div>
        </div>
      </CarouselSwipe>
      <TituloPagina Children="Fachadas Ventiladas" />
      <div className="backColor">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-6">
              <img src={CorteFachada} alt="" className="w-100" />
            </div>

            <div className="col-md-6">
              <img src={FachadaVentilada} alt="" className="w-100" />
            </div>
          </div>
        </div>
      </div>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-6">
            <h3>
              <b>Ventajas de las fachadas ventiladas</b>
            </h3>
            <ul className="list-unstyled fachadaLista">
              <li>- Amplias posibilidades de diseño.</li>
              <li>- Mayor protección al crear un segundo revestimiento.</li>
              <li>- Importante mejora del rendimiento térmico del edificio.</li>
              <li>- Resistencia a condiciones exteriores.</li>
              <li>- Mejora sensible de las calidades acústicas.</li>
              <li>- Facilidad de limpieza</li>
              <li>- Instalación rápida</li>
              <li>- Amplia variedad de colores a elegir.</li>
              <li>- Material aprobado para proyectos LEED.</li>
            </ul>
          </div>
          <div className="col-md-6">
            <ColoresFachada />
          </div>
        </div>
      </div>
      <div className="backColor">
        <div className="container py-5">
          <div className="row">
            <h3 className="text-center">
              <b>
                Composición y resistencia del Sólido fenolico para exteriores
              </b>
            </h3>
            <div className="col-md-6">
              <img src={croquis} alt="" className="w-100" />
            </div>
            <div className="col-md-6">
              <img src={resistencia} alt="" className="w-75" />
            </div>
          </div>
        </div>
      </div>
      <div className="container py-5">
        <h3 className="text-center">
          <b>Certificaciones internacionales del Sólido Fenólico</b>
        </h3>
        <Certificaciones
          FSC={true}
          // GreenGuardGOLD={true}
          GreenGuard={true}
          // IMSS={true}
          // ISO9001={true}
          // ISO90012000={true}
          ISO={true}
          // NFPAMember
          // NFPA={true}
          // NSFInternacional={true}
          PEFC={true}
          SCSGlobal={true}
          // ULGreenguard={true}
          UL={true}
          USGBC={true}
        />
      </div>
    </div>
  );
};

export default Fachadas;
