import React from "react";
import ImagenGlobal from "../../components/ImagenGlobal/ImagenGlobal";
import CarouselSwipe from "../../components/Carousel";
import "./styles.css";

import solido1 from "../../assets/SuperficieSolida/Beneficios_Natural.svg";
import solido2 from "../../assets/SuperficieSolida/Beneficios_Antibacterial.svg";
import solido3 from "../../assets/SuperficieSolida/Beneficios_Reciclable.svg";
import solido4 from "../../assets/SuperficieSolida/Beneficios_Limpieza.svg";
import solido5 from "../../assets/SuperficieSolida/Beneficios_No_poroso.svg";
import solido6 from "../../assets/SuperficieSolida/Beneficios_Ligero.svg";
import solido7 from "../../assets/SuperficieSolida/Beneficios_Uniones-invisibles.svg";
import solido8 from "../../assets/SuperficieSolida/Beneficios_Reparable.svg";
import solido9 from "../../assets/SuperficieSolida/Beneficios_Termocurveable.svg";
import linea1 from "../../assets/SuperficieSolida/1573MG-frosty-white-mirage.jpg";
import linea2 from "../../assets/SuperficieSolida/Alabaster Mirage D431-MG.jpg";
import linea3 from "../../assets/SuperficieSolida/Black-Lava-F1-9020.jpg";
import linea4 from "../../assets/SuperficieSolida/9214cm-asteroid.jpg";
import linea5 from "../../assets/SuperficieSolida/Designer White D354-SL.jpg";
import linea6 from "../../assets/SuperficieSolida/9033ML-caramel-melange.jpg";
import Aplicaciones from "../../components/Aplicaciones/Aplicaciones";
import Certificaciones from "../../components/Certificaciones/Certificaciones";

// CARROUSEL
import imagen1 from "../../assets/SuperficieSolida/SLIDE-SS-01.jpg";
import imagen2 from "../../assets/SuperficieSolida/SLIDE-SS-02.jpg";
import imagen3 from "../../assets/SuperficieSolida/SLIDE-SS-03.jpg";

const SuperficieSolida = () => {
  return (
    <div className="marginTop">
      {/* TODO: CARROUSEL Cambiar imágenes */}
      <CarouselSwipe className="banner" images={[imagen1, imagen2, imagen3]}>
        <div
          style={{ height: "500px", position: "absolute" }}
          className="carrousel-container w-100 d-flex justify-content-center align-items-center px-3 px-md-5"
        >
          <div className="texto-encima w-100 p-5 d-flex flex-column justify-content-center align-items-center">
            <h1 className="mb-5 text-white">
              <b>Superficie Sólida 3Gar</b>
            </h1>
            <h5 className="title-text">
              Las superficies sólida es un material
            </h5>
            <h5 className="title-text">
              compuesto por minerales naturales y resina
            </h5>
            <h5 className="title-text">
              acrílica de gran resistencia, higiénico y
            </h5>
            <h5 className="title-text">
              perfecto para elaborar cualquier cosa que puedas imaginar.
            </h5>
            {/* MUCHO TEXTO "Gracias a su versatilidad podemos lograr espacios únicos, elegantes, con gran variedad de colores. Por todo lo antes mencionado, se usa en baños, cocinas y como revestimiento." */}
          </div>
        </div>
      </CarouselSwipe>
      <div className="backColor">
        <div className="container text-center py-5">
          <h3 className="text-primary">
            <b>Características</b>
          </h3>
          <p>
            Es un material compuesto por minerales naturales y resina acrílica
            de gran resistencia y con gran proyección en el diseño de interiores
            y exteriores
          </p>
          <p>
            Ideal para cubiertas de lavabos, cocinas, muebles de oficina e
            incluso recubrimiento de fachada
          </p>
        </div>
      </div>
      <div className="container py-5 text-center">
        <div className="row">
          <div className="col-md-6">
            <h3>
              <b>Beneficios</b>
            </h3>
            <div className="row">
              <ImagenGlobal text="Natural" src={solido1} size="4" />
              <ImagenGlobal text="Antibacterial" src={solido2} size="4" />
              <ImagenGlobal text="100% recicable" src={solido3} size="4" />
              <ImagenGlobal text="Fácil de limpiar" src={solido4} size="4" />
              <ImagenGlobal text="No poroso" src={solido5} size="4" />
              <ImagenGlobal text="Bajo peso" src={solido6} size="4" />
              <ImagenGlobal text="Uniones invisibles" src={solido7} size="4" />
              <ImagenGlobal text="Se puede reparar" src={solido8} size="4" />
              <ImagenGlobal text="Termocurveable" src={solido9} size="4" />
            </div>
          </div>
          <div className="col-md-6">
            <h3>
              <b>Colores de línea</b>
            </h3>
            <div className="row">
              <ImagenGlobal
                imgClass="w-100"
                text="Designer White"
                src={linea1}
                size="4 col-sm-4 col-lg-4"
              >
                <p>D354-SL</p>
              </ImagenGlobal>
              <ImagenGlobal
                imgClass="w-100"
                text="Alabaster Mirage"
                src={linea2}
                size="4 col-sm-4 col-lg-4"
              >
                <p>D431-MG</p>
              </ImagenGlobal>
              <ImagenGlobal
                imgClass="w-100"
                text="Black Lava"
                src={linea3}
                size="4 col-sm-4 col-lg-4"
              >
                <p>F1-9020</p>
              </ImagenGlobal>
              <ImagenGlobal
                imgClass="w-100"
                text="Frosty White"
                src={linea4}
                size="4 col-sm-4 col-lg-4"
              >
                <p>1573-SL</p>
              </ImagenGlobal>
              <ImagenGlobal
                imgClass="w-100"
                text="Frosty White Mirage"
                src={linea5}
                size="4 col-sm-4 col-lg-4"
              >
                <p>1573-MG</p>
              </ImagenGlobal>
              <ImagenGlobal
                imgClass="w-100"
                text="Caramel Melange"
                src={linea6}
                size="4 col-sm-4 col-lg-4"
              >
                <p>9033-ML</p>
              </ImagenGlobal>
            </div>
            <p className="text-primary text-start">
              <b>
                Nota: Esta tabla es ilustrativa para la sección de colores de
                nuestros productos. Los colores pueden variar su tonalidad en la
                pantalla. Solicite muestras físicas antes de realizar su pedido.
              </b>
            </p>
            {/* TODO: COLORES Abrir archivo */}
            <button
              onClick={null}
              className="btn btn-primary px-4 rounded-pill my-4"
            >
              Ver más colores
            </button>
          </div>
        </div>
      </div>
      <div className="backColor">
        <div className="container py-5">
          <h3 className="text-center">
            <b>Aplicaciones</b>
          </h3>
          <Aplicaciones />
        </div>
      </div>
      <div className="container py-5">
        <h3 className="text-center">
          <b>Certificaciones</b>
        </h3>
        <Certificaciones
          // FSC={true}
          GreenGuardGOLD={true}
          // GreenGuard={true}
          // IMSS={true}
          // ISO9001={true}
          ISO90012000={true}
          // ISO={true}
          // NFPAMember
          NFPA={true}
          NSFInternacional={true}
          // PEFC={true}
          SCSGlobal={true}
          // ULGreenguard={true}
          UL={true}
          USGBC={true}
        />
      </div>
    </div>
  );
};

export default SuperficieSolida;
